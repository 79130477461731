import React, { FC } from 'react';

const Footer: FC = () => {
    return (
       <div className="footer">

           <p>Luis Araujo 2024 </p>
           
       </div>
    );
}

export default Footer;
